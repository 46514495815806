import React from "react"
import styles from "./Button.module.scss"
import cx from "classnames"

function Button({
  className,
  as = "button",
  asStyle = "button", // button, link
  children = [],
  variant = null,
  inverted = false,
  iconLeft = null,
  iconRight = null,
  padding = "md", // none, md
  textBold = true,
  ...rootProps
}) {
  const EL = as

  return (
    <EL
      data-variant={variant}
      className={cx(
        styles.Button,
        {
          [styles.primary]: variant === "primary",
          [styles.secondary]: variant === "secondary",
          [styles.tertiary]: variant === "tertiary",
          [styles.inverted]: inverted,
          [styles.asLink]: asStyle === "link",
          [styles.asButton]: asStyle === "button",
          [styles[`padding-${padding}`]]: padding,
          "font-bold": textBold,
        },
        className,
      )}
      {...rootProps}
    >
      {iconLeft ? <i className={styles.icon}>{iconLeft}</i> : null}
      {children}
      {iconRight ? <i className={styles.icon}>{iconRight}</i> : null}
    </EL>
  )
}

export default Button

import Header from "../Header"
import Footer from "../Footer"
import { NextSeo } from "next-seo"
import Head from "next/head"
import { useRouter } from "next/router"
import styles from "./Layout.module.scss"
import BookmarkSidebar from "../JobSearch/BookmarkSidebar"
import cx from "classnames"

const isProdEnv = process.env.NODE_ENV === "production"

function Layout({
  children = {},
  content = {},
  globals = {},
  localization = {},
  jobInformation = {},
  seoOverrides = {},
  className = "",
}) {
  const { asPath } = useRouter()

  const origin = process.env.NEXT_PUBLIC_SITE_URL
    ? process.env.NEXT_PUBLIC_SITE_URL
    : "https://www.toom.de"

  const _pathSliceLength = Math.min.apply(Math, [
    asPath.includes("?") ? asPath.indexOf("?") : asPath.length,
    asPath.includes("#") ? asPath.indexOf("#") : asPath.length,
  ])

  const canonical = `${origin}${asPath.substring(0, _pathSliceLength)}`

  const title =
    jobInformation?.title || content?.seo_title || content?.title || "Toom"

  const ogData = {
    url: canonical,
    title,
    description: content?.seo_description || null,
    images: [],
  }

  if (content?.seo_image?.permalink) {
    ogData.images.push({
      url: content?.seo_image?.permalink,
      width: content?.seo_image?.width,
      height: content?.seo_image?.height,
      alt: content?.seo_image?.alt,
    })
  }

  // seo properties from outside will overwrite base props
  let seoProps = {
    canonical,
    title,
    description: content?.seo_description || "",
    noindex: !isProdEnv || content?.seo_noindex,
    nofollow: !isProdEnv || content?.seo_nofollow,
    openGraph: ogData,
  }

  if (seoOverrides?.noindex) {
    seoProps.noindex = seoOverrides.noindex
  }

  if (seoOverrides?.nofollow) {
    seoProps.nofollow = seoOverrides.nofollow
  }

  return (
    <>
      <NextSeo {...seoProps} />

      <Head>
        {localization?.page?.locale_variants?.map((variant) => (
          <link
            key={variant.locale}
            rel="alternate"
            hrefLang={variant.locale}
            href={origin + variant.url}
          />
        ))}
      </Head>

      <Header
        globals={globals}
        navigation={globals?.headerNavigation}
        localization={localization}
      />

      <div className={cx(styles.main, className)}>
        <main className={styles.content}>{children}</main>

        <Footer
          navigation={globals?.footerNavigation}
          footerInformation={globals?.footerInformation}
        />

        <BookmarkSidebar />
      </div>
    </>
  )
}

export default Layout

import styles from "./JobCard.module.scss"
import Badge from "../../Badge"
import { MapPin } from "react-feather"
import cx from "classnames"
import useBookmark from "../../../lib/use-bookmark"
import Button from "../../FormInputs/Button"
import useTranslation from "next-translate/useTranslation"
import { useDispatch, useSelector } from "react-redux"
import { setMapSelectedJob } from "../../../store/slices/searchSlice"
import Link from "next/link"
import BookmarkButton from "../BookmarkButton"
import Skeleton from "../../Skeleton"

const JobCard = ({
  className = "",
  job,
  variant = "job",
  showSkeleton = false,
}) => {
  const { t } = useTranslation("jobsearch")

  const dispatch = useDispatch()
  const search = useSelector((state) => state.search)

  return showSkeleton ? (
    <div
      className={cx(
        styles.JobCard,
        {
          [styles[variant]]: variant,
        },
        className,
        styles.loading,
      )}
      tabIndex={0}
    >
      <header>
        <div className={styles.timing}>
          <Skeleton width={150} height={22} />
        </div>
        <BookmarkButton
          singleJobId={null}
          buttonAsStyle={"link"}
          buttonShowText={false}
          iconSize={variant === "map" ? 20 : 24}
          skeleton={true}
        />
      </header>
      <div className={styles.title}>
        <Skeleton width={200} height={32} />
      </div>
      {!(variant === "map" || variant === "map_sidebar") ? (
        <>
          <div className={styles.city}>
            <Skeleton width={100} height={20} />
          </div>
          <div className={styles.targetGroups}>
            <Skeleton width={200} height={32} rounded={true} />
          </div>
        </>
      ) : null}

      {variant === "map_sidebar" ? (
        <div className={"flex flex-nowrap items-end justify-between"}>
          <div className={styles.city} />
          <div className={cx(styles.mapIcon)}>
            {!search?.isLoading ? <MapPin size={20} /> : null}
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <div
      className={cx(
        styles.JobCard,
        {
          [styles[variant]]: variant,
        },
        className,
      )}
      tabIndex={0}
    >
      {variant === "job" || variant === "map" || variant === "map_sidebar" ? (
        <a href={`/jobs/${job.slug}`}>
          <header>
            <div className={styles.timing}>
              <span>{job.earliestStart}</span> |{" "}
              <span>{job.workingtimes?.map((wt) => wt.name).join(", ")}</span>
            </div>
            <BookmarkButton
              singleJobId={job?.externalId}
              buttonAsStyle={"link"}
              buttonShowText={false}
              iconSize={variant === "map" ? 20 : 24}
            />
          </header>
          <div className={styles.title}>{job.title}</div>
          {!(variant === "map" || variant === "map_sidebar") ? (
            <>
              <div className={styles.city}>{job.city}</div>
              <div className={styles.targetGroups}>
                {job.targetGroups?.map((tg, i) => (
                  <Badge color={"green"} key={i}>
                    {tg.name}
                  </Badge>
                ))}
              </div>
            </>
          ) : null}

          {variant === "map_sidebar" ? (
            <div className={"flex flex-nowrap items-end justify-between"}>
              <div className={styles.city}>{job.city}</div>
              <div
                className={cx(styles.mapIcon, {
                  [styles.active]: search.mapSelectedJob === job.id,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  dispatch(
                    setMapSelectedJob(
                      search.mapSelectedJob === job.id ? null : job.id,
                    ),
                  )
                }}
              >
                <MapPin size={20} />
              </div>
            </div>
          ) : null}
        </a>
      ) : (
        <>
          <div className={styles.title}>{t("results.initiative.title")}</div>
          <div className={styles.text}>{t("results.initiative.text")}</div>
          <div>
            <a
              href={"https://toom-baumarkt.dvinci-easy.com/de/p/Initiativ/jobs"}
              target={"_blank"}
              rel={"noreferrer nofollow"}
            >
              <Button variant={"tertiary"} className={"bg-transparent"}>
                {t("results.initiative.button")}
              </Button>
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export default JobCard

"use client"

import { useAppDispatch, useAppSelector } from "../store"
import { getBookmarkedJobs, setBookmarks } from "../store/slices/bookmarksSlice"
import { useEffect, useState } from "react"

const useBookmark = (jobId) => {
  const [isBookmarked, setIsBookmarked] = useState(false)

  const dispatch = useAppDispatch()
  const bookmarks = useAppSelector((state) => state.bookmarks.bookmarks)

  useEffect(() => {
    if (!bookmarks || !Array.isArray(bookmarks) || !bookmarks.length) {
      setIsBookmarked(false)
      return
    }

    setIsBookmarked(bookmarks.indexOf(jobId) !== -1)
  }, [bookmarks])

  const toggleBookmark = () => {
    if (isBookmarked) {
      if (bookmarks) {
        dispatch(
          setBookmarks(bookmarks.filter((bookmark) => bookmark !== jobId)),
        )
      } else {
        dispatch(setBookmarks([]))
      }
    } else {
      if (!bookmarks) {
        dispatch(setBookmarks([jobId]))
      } else {
        dispatch(setBookmarks([...bookmarks, jobId]))
      }
    }

    setIsBookmarked(!isBookmarked)
  }

  return [isBookmarked, toggleBookmark]
}

export default useBookmark

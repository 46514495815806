import styles from "./ResultList.module.scss"
import cx from "classnames"
import JobCard from "../JobCard"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"

function ResultList({
  results = [],
  className = "",
  resultClassName = "",
  display = "grid",
  variant = "job",
  resultVariant = "job",
  showInitiative = true,
}) {
  const search = useSelector((state) => state.search)

  const [isLastPage, setIsLastPage] = useState(false)
  useEffect(() => {
    setIsLastPage(search?.meta?.page === search?.meta?.totalPages)
  }, [search])

  return (
    <div
      className={cx(
        styles.ResultList,
        {
          [styles[`displayGrid`]]: display === "grid",
          [styles[`displayList`]]: display === "list" || variant === "map",
          [styles.map]: variant === "map",
        },
        className,
      )}
    >
      {search?.isLoading ? (
        <>
          <JobCard
            key={"job-skeleton-1"}
            variant={resultVariant}
            className={resultClassName}
            showSkeleton={true}
          />
          <JobCard
            key={"job-skeleton-2"}
            variant={resultVariant}
            className={resultClassName}
            showSkeleton={true}
          />
          <JobCard
            key={"job-skeleton-3"}
            variant={resultVariant}
            className={resultClassName}
            showSkeleton={true}
          />
          <JobCard
            key={"job-skeleton-4"}
            variant={resultVariant}
            className={resultClassName}
            showSkeleton={true}
          />
        </>
      ) : null}

      {!search?.isLoading
        ? results.map((result, i) => (
            <JobCard
              key={"job-" + i}
              job={result}
              className={resultClassName}
              variant={resultVariant}
            />
          ))
        : null}

      {!search?.isLoading &&
      showInitiative &&
      !(variant === "map" || variant === "map_sidebar") ? (
        <JobCard
          key={"initiative"}
          variant={"initiative"}
          className={cx(resultClassName, "col-span-2")}
        />
      ) : null}
    </div>
  )
}

export default ResultList

import cx from "classnames"
import styles from "./Section.module.scss"
import { useEffect } from "react"
import { dedentBlockStringLines } from "graphql/language/blockString"

export const Section = ({
  children = {},
  className = "",
  container = true,
  extraSpacing = false,
  sectionSpacing = {
    top: null,
    bottom: null,
  },
  noPadding = false,
}) => {
  // console.log("Section", sectionSpacing)

  if (sectionSpacing.top === null) {
    sectionSpacing.top = "medium"
  }

  if (sectionSpacing.bottom === null) {
    sectionSpacing.bottom = "medium"
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!entry.target.classList.contains(styles.animation)) {
              entry.target.classList.add(styles.animation)
            }
          }
        })
      },
      {
        rootMargin: "0px 0px 0px 0px",
        threshold: 0,
      },
    )

    const sections = document.querySelectorAll("section")
    sections.forEach((section) => {
      observer.observe(section)
    })

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section)
      })
    }
  }, [])

  return (
    <section
      data-s-top={sectionSpacing.top}
      data-s-bottom={sectionSpacing.bottom}
      className={cx(
        styles.Section,
        {
          [styles.hasContainer]: container,
          [styles.extraSpacing]: extraSpacing,
          [styles[`paddingTop-${sectionSpacing.top}`]]:
            !noPadding && sectionSpacing.top,
          [styles[`paddingBottom-${sectionSpacing.bottom}`]]:
            !noPadding && sectionSpacing.bottom,
        },
        className,
      )}
    >
      {children}
    </section>
  )
}

export default Section

import styles from "./Header.module.scss"
import Image from "../Image"
import { useEffect, useRef, useState } from "react"
import cx from "classnames"
import Link from "next/link"
import { ChevronDown, ChevronLeft, ChevronRight, Menu, X } from "react-feather"
import { useRouter } from "next/router"
import useTranslation from "next-translate/useTranslation"
import BookmarkButton from "../JobSearch/BookmarkButton"
import logoKarriereIMG from "../../public/images/logos/logo_karriere.png"
import { useDispatch, useSelector } from "react-redux"
import Button from "../FormInputs/Button"

function Header({ navigation = {}, className }) {
  const { t } = useTranslation("jobsearch")

  const { asPath } = useRouter()

  const dispatch = useDispatch()
  const pageStore = useSelector((state) => state.page)

  /* HIDE HEADER */
  const headerRef = useRef(null)
  const [lastScrollY, _setLastScrollY] = useState(0)
  //
  // // HACK
  // const lastScrollYRef = useRef(lastScrollY)
  // const setLastScrollY = (data) => {
  //   lastScrollYRef.current = data
  //   _setLastScrollY(data)
  // }
  //
  // const checkHeaderVisibility = () => {
  //   if (typeof window !== "undefined") {
  //     if (!mobileMenuOpen) {
  //       if (
  //         window.scrollY > lastScrollYRef.current &&
  //         window.scrollY > headerRef.current.offsetHeight
  //       ) {
  //         dispatch(setHeaderVisible(false))
  //       } else {
  //         dispatch(setHeaderVisible(true))
  //       }
  //       setLastScrollY(window.scrollY)
  //     }
  //   }
  // }
  //
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.addEventListener("scroll", checkHeaderVisibility, {
  //       passive: true,
  //     })
  //
  //     // cleanup function
  //     return () => {
  //       window.removeEventListener("scroll", checkHeaderVisibility)
  //     }
  //   } else if (typeof window !== "undefined") {
  //     window.removeEventListener("scroll", checkHeaderVisibility)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  /* END HIDE HEADER */

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileNavItems, setMobileNavItems] = useState([])
  const [mobileNavItemsDisplay, setMobileNavItemsDisplay] = useState([])
  const [isInSubNav, setIsInSubNav] = useState(false)
  useEffect(() => {
    document.body.style.overflow = mobileMenuOpen ? "hidden" : "auto"
  }, [mobileMenuOpen])

  useEffect(() => {
    setMobileNavItems(navigation || [])
    setMobileNavItemsDisplay(navigation || [])
  }, [navigation])

  const changeMobileSubNav = (parent) => {
    if (parent?.children) {
      setMobileNavItemsDisplay(parent.children)
      setIsInSubNav(true)
    }
  }

  const resetMobileSubNav = () => {
    setMobileNavItemsDisplay(mobileNavItems)
    setIsInSubNav(false)
  }

  const openMobileNav = () => {
    resetMobileSubNav()
    setMobileMenuOpen(true)
  }

  const closeMobileNav = () => {
    setMobileMenuOpen(false)
    resetMobileSubNav()
  }

  const [submenuOpen, setSubmenuOpen] = useState(null)
  useEffect(() => {
    setSubmenuOpen(
      navigation
        ?.filter((navItem) => navItem.position === "left")
        ?.map((navItem) => false),
    )
  }, [])

  const openSubmenu = (index) => {
    if (
      !submenuOpen ||
      (submenuOpen && Array.isArray(submenuOpen) && submenuOpen.length === 0)
    ) {
      return
    }
    setSubmenuOpen(submenuOpen.map((item, i) => i === index))
  }

  const closeSubmenu = (index) => {
    if (
      !submenuOpen ||
      (submenuOpen && Array.isArray(submenuOpen) && submenuOpen.length === 0)
    ) {
      return
    }
    setSubmenuOpen(submenuOpen.map((item, i) => false))
  }

  return (
    <header
      ref={headerRef}
      className={cx(styles.header, className, {
        [styles.invisible]: !pageStore?.headerVisible,
      })}
    >
      <div className={styles.headerInner}>
        <div className={"flex items-center gap-16"}>
          <a href={"/"} className={styles.logo}>
            <Image
              alt={"Toom Logo"}
              src={logoKarriereIMG}
              sizes={"(max-width: 1440px) 162px, 216px"}
              fill
              style={{
                objectPosition: "center",
                objectFit: "contain",
              }}
            />
          </a>

          {navigation?.length ? (
            <nav className={styles.navWrapper}>
              <ul>
                {navigation
                  ?.filter((navItem) => navItem.position === "left")
                  ?.map((navItem, i) => (
                    <li
                      key={navItem.url}
                      onMouseOver={() => {
                        openSubmenu(i)
                      }}
                      onMouseOut={() => {
                        closeSubmenu()
                      }}
                    >
                      {navItem?.url && navItem.url !== "#" ? (
                        <a
                          key={navItem.title}
                          href={navItem.url}
                          title={navItem.title}
                          onClick={(e) => {
                            closeSubmenu()
                          }}
                        >
                          <span
                            className={cx({
                              [styles.active]: asPath === navItem.url,
                            })}
                          >
                            {navItem.title}
                          </span>
                          {navItem.children?.length > 0 ? (
                            <ChevronDown size={24} />
                          ) : null}
                        </a>
                      ) : (
                        <span
                          key={navItem.title}
                          href={navItem.url}
                          title={navItem.title}
                          onClick={(e) => {
                            closeSubmenu()
                          }}
                        >
                          <span
                            className={cx({
                              [styles.active]: asPath === navItem.url,
                            })}
                          >
                            {navItem.title}
                          </span>
                          {navItem.children?.length > 0 ? (
                            <ChevronDown size={24} />
                          ) : null}
                        </span>
                      )}

                      {navItem.children?.length > 0 ? (
                        <ul
                          className={cx(styles.navSubmenu, {
                            [styles.open]: submenuOpen?.[i] ?? false,
                          })}
                        >
                          {navItem.children?.map((childNavitem, i) => (
                            <li
                              key={`${childNavitem.url}-${i}`}
                              onClick={(e) => {
                                closeSubmenu()
                              }}
                              className={cx({
                                [styles.separator]:
                                  childNavitem.title.startsWith("=="),
                              })}
                            >
                              {childNavitem.title.startsWith("==") ? (
                                <span>
                                  {childNavitem.title.replace(/^=+\s?/, "")}
                                </span>
                              ) : (
                                <a
                                  href={childNavitem.url}
                                  title={childNavitem.title}
                                  className={"w-full"}
                                >
                                  <span>{childNavitem.title}</span>
                                </a>
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  ))}
              </ul>
            </nav>
          ) : null}
        </div>

        <div>
          {navigation?.length ? (
            <nav className={cx(styles.navWrapper, styles.noAnimation)}>
              <ul>
                <li className={"flex items-center gap-2"}>
                  <BookmarkButton
                    variant={"header"}
                    buttonAsStyle={"link"}
                    iconPosition={"left"}
                    buttonShowText={false}
                    hideIfNoBookmarks={true}
                    showBadge={true}
                    role={"button"}
                  />
                </li>
                {navigation
                  ?.filter((navItem) => navItem.position === "right")
                  ?.map((navItem) => (
                    <li key={navItem.url}>
                      <a
                        key={navItem.title}
                        href={navItem.url}
                        title={navItem.title}
                      >
                        <Button variant={"secondary"}>{navItem.title}</Button>
                        {navItem.children?.length ? (
                          <ChevronDown size={24} />
                        ) : null}{" "}
                      </a>
                    </li>
                  ))}
              </ul>
            </nav>
          ) : null}
        </div>

        <div className={"flex items-center gap-4 xl:hidden"}>
          <div className={styles.mobileBookmarkButton}>
            <BookmarkButton
              variant={"header_mobile"}
              iconPosition={"left"}
              buttonAsStyle={"link"}
              buttonShowText={false}
              hideIfNoBookmarks={true}
              showBadge={true}
            />
          </div>

          <div
            className={styles.mobileNavButton}
            onClick={mobileMenuOpen ? closeMobileNav : openMobileNav}
          >
            {mobileMenuOpen ? <X /> : <Menu />}
          </div>
        </div>

        {mobileNavItemsDisplay?.length ? (
          <div
            className={cx(styles.mobileNavWrapper, {
              [styles.open]: mobileMenuOpen,
            })}
          >
            <nav className={styles.mobileNav}>
              <ul>
                {isInSubNav ? (
                  <li key={"mNavBack"} onClick={resetMobileSubNav}>
                    <div className="flex items-center font-normal text-toom-red">
                      <ChevronLeft /> zurück
                    </div>
                  </li>
                ) : null}

                {mobileNavItemsDisplay?.map((navItem) => (
                  <li key={navItem.url}>
                    <a
                      key={navItem.title}
                      href={navItem.url}
                      title={navItem.title}
                      onClick={(e) => {
                        setMobileMenuOpen(false)
                      }}
                    >
                      <span
                        className={cx({
                          [styles.active]: asPath === navItem.url,
                        })}
                      >
                        {navItem.title}
                      </span>
                      <div
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()

                          if (navItem?.children?.length) {
                            changeMobileSubNav(navItem)
                          }
                        }}
                      >
                        {navItem.children?.length ? (
                          <ChevronRight size={24} />
                        ) : null}{" "}
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        ) : null}
      </div>
    </header>
  )
}

export default Header

import styles from "./Footer.module.scss"
import Image from "../Image"
import Link from "next/link"
import useTranslation from "next-translate/useTranslation"

function Footer({ navigation = [], footerInformation = {} }) {
  // console.log("Footer", { navigation, footerInformation })

  const { t } = useTranslation("common")

  return (
    <footer className={styles.footer}>
      <div className={styles.topWrapper}>
        {footerInformation?.links?.length ? (
          <div className={styles.links}>
            <div className={styles.linksHeadline}>
              {footerInformation.links_headline}
            </div>
            <ul className={styles.linksList}>
              {footerInformation.links.map((item, i) => (
                <li key={i} className={styles.link}>
                  <a
                    href={item?.link}
                    target={item?.target?.value || "_self"}
                    rel={
                      item?.target?.value !== "_self"
                        ? "noreferer nofollow"
                        : ""
                    }
                  >
                    {item?.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        {footerInformation?.platforms?.length ? (
          <div className={styles.socialmedia}>
            <div className={styles.socialmediaHeadline}>
              {footerInformation.socialmedia_headline}
            </div>
            <div className={styles.socialmediaText}>
              {footerInformation.socialmedia_text}
            </div>
            <ul className={styles.platforms}>
              {footerInformation.platforms.map((socialPresence, i) => (
                <li key={i} className={styles.socialPresence}>
                  <a
                    href={socialPresence?.link}
                    title={socialPresence?.title}
                    target={"_blank"}
                  >
                    <Image
                      alt={socialPresence?.image?.alt}
                      src={socialPresence?.image?.permalink}
                      width={36}
                      height={36}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        {footerInformation?.awards?.length ? (
          <ul className={styles.awards}>
            {footerInformation.awards.map((award, i) => (
              <li key={i}>
                <Image
                  className={styles.award}
                  alt={award?.alt}
                  src={award?.permalink}
                  width={award?.width}
                  height={award?.height}
                  style={{
                    objectPosition: "center",
                    objectFit: "contain",
                  }}
                />
              </li>
            ))}
          </ul>
        ) : null}

        {footerInformation?.company_text ? (
          <div
            className={styles.companyText}
            dangerouslySetInnerHTML={{
              __html: footerInformation.company_text,
            }}
          />
        ) : null}
      </div>

      <div className={styles.bottomWrapper}>
        <div className={styles.innerNav}>
          <ul className={styles.nav}>
            {navigation?.map((item, i) => (
              <li key={i} className={styles.navEntry}>
                <a href={item?.url}>{item?.title}</a>
              </li>
            ))}

            <li key={"privacy"} className={styles.navEntry}>
              <a
                href={"#"}
                onClick={(e) => {
                  e.preventDefault()
                  if (window.UC_UI && window.UC_UI.isInitialized()) {
                    window.UC_UI.showSecondLayer()
                  }
                }}
              >
                {t("privacy.settingsLink")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer

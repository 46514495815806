import styles from "./Badge.module.scss"
import cx from "classnames"

const Badge = ({
  className,
  children,
  color,
  border = false,
  circle = false,
  padding = "md", // md, lg
  ...rootProps
}) => {
  const contentLength = children?.toString().length

  return (
    <span
      className={cx(
        styles.Badge,
        {
          [styles[color]]: !!color,
          [styles.hasBorder]: border,
          [styles.circle]: circle,
          [styles.l1]: circle && contentLength === 1,
          [styles.l2]: circle && contentLength >= 2,
          [styles[`padding-${padding}`]]: padding,
        },
        className,
      )}
      {...rootProps}
    >
      {children}
    </span>
  )
}

export default Badge

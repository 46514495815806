"use client"

import React, { useEffect, useState } from "react"
import styles from "./BookmarkSidebar.module.scss"
import { X } from "react-feather"
import useTranslation from "next-translate/useTranslation"
import cx from "classnames"
import useBookmarks from "../../../lib/use-bookmarks"
import Button from "../../FormInputs/Button"
import {
  getBookmarkedJobs,
  setSidebarOpen,
} from "../../../store/slices/bookmarksSlice"
import { useDispatch } from "react-redux"
import ResultList from "../ResultList"

function BookmarkSidebar({ className, variant = "button" }) {
  const { t } = useTranslation("jobsearch")

  const [hasBookmarks, setHasBookmarks] = useState(false)
  const dispatch = useDispatch()

  const bookmarks = useBookmarks()

  useEffect(() => {
    setHasBookmarks(bookmarks?.bookmarks?.length > 0 || false)
  }, [bookmarks?.bookmarks])

  useEffect(() => {
    document
      .querySelector("body")
      .classList.toggle("no-scroll", bookmarks?.sidebar_open)

    dispatch(getBookmarkedJobs())
  }, [bookmarks?.sidebar_open])

  return (
    <div
      suppressHydrationWarning={true}
      className={cx(
        styles.BookmarkSidebar,
        {
          [styles.visible]: bookmarks?.sidebar_open,
        },
        className,
      )}
    >
      <header className={styles.header}>
        <div className={"flex items-center gap-2"}>
          <Button
            variant={"tertiary"}
            asStyle={"link"}
            iconRight={<X />}
            onClick={() => {
              dispatch(setSidebarOpen(!bookmarks?.sidebar_open))
            }}
          >
            <span>{t("bookmarks.sidebar.close")}</span>
          </Button>
        </div>
      </header>

      {hasBookmarks ? (
        <ResultList
          display={"list"}
          className={"h-full max-h-full gap-0 overflow-y-auto"}
          resultClassName={"border-b-0 last:mb-[88px]"}
          results={bookmarks?.bookmarkedJobs}
          showInitiative={false}
        />
      ) : (
        <div className={styles.noBookmarksWrapper}>
          <div className={styles.title}>{t("bookmarks.noBookmarks.title")}</div>
          <div className={styles.text}>{t("bookmarks.noBookmarks.text")}</div>
        </div>
      )}
    </div>
  )
}

export default BookmarkSidebar

import React from "react"
import * as Checkbox from "@radix-ui/react-checkbox"
import styles from "./CheckBox.module.scss"
import { Check } from "react-feather"
import { uuid } from "../../../lib/util"
import cx from "classnames"

function CheckBox({
  children = [],
  cKey = "",
  variant = null,
  className = "",
  boxClassName = "",
  ...rootProps
}) {
  const id = cKey || `c-${uuid()}`

  return (
    <div className={cx("flex items-center gap-2", className)}>
      <Checkbox.Root
        id={id}
        className={cx(
          styles.CheckBox,
          {
            [styles["on-red-bg"]]: variant === "on-red-bg",
          },
          boxClassName,
        )}
        aria-labelledby={`lbl-${id}`}
        {...rootProps}
      >
        <Checkbox.Indicator className={styles.indicator}>
          <Check />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label id={`lbl-${id}`} className={"mt-0.5 text-sm"} htmlFor={id}>
        {children}
      </label>
    </div>
  )
}

export default CheckBox

export const uuid = () => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36)
}

export const slugify = (text) => {
  text = text?.toString()?.toLowerCase()

  text = text?.replaceAll(/(<([^>]+)>)/gi, "")

  text = text
    ?.replace(/\s+/g, "-") // Replace spaces with -
    ?.replace(/[^\w-]+/g, "") // Remove all non-word chars
    ?.replace(/--+/g, "-") // Replace multiple - with single -
    ?.replace(/^-+/, "") // Trim - from start of text
    ?.replace(/-+$/, "") // Trim - from end of text

  return text
}

export const stripHtml = (html) => {
  // remove outer p tags from string
  const pTagRegex = /<p>(.*?)<\/p>/g
  const matches = html.match(pTagRegex)
  if (matches && matches.length) {
    html = matches
      .map((match) => match.replace(/<p>/g, "").replace(/<\/p>/g, "<br/>"))
      .join("")
  }

  // remove last br tag from string
  html = html.replace(/<br\/>$/, "")

  return html
}

import NextImage from "next/image"

export const Image = (props) => {
  const src = props.src
  if (!src) return null

  const isSvg =
    typeof src === "object" || (typeof src === "string" && src.endsWith(".svg"))

  const url = isSvg ? src : props?.src?.toString().replaceAll(" ", "%20")

  const alt = props?.alt ?? ""

  return (
    <NextImage
      {...props}
      alt={alt}
      src={url}
      placeholder={"empty"}
      blurDataURL={getImageUrl(isSvg, url, 10, 80)}
      loader={({ src, width, quality }) =>
        getImageUrl(isSvg, src, width, quality || 80)
      }
    />
  )
}

const getImageUrl = (isSvg, url, width, quality = 80) => {
  if (isSvg) return url
  return `/_next/image?w=${width}&q=${quality}&url=${url}`
}

export default Image

"use client"

import React, { useEffect, useState } from "react"
import styles from "./BookmarkButton.module.scss"
import Button from "../../FormInputs/Button"
import { Star } from "react-feather"
import useTranslation from "next-translate/useTranslation"
import cx from "classnames"
import useBookmarks from "../../../lib/use-bookmarks"
import { useDispatch } from "react-redux"
import {
  getBookmarkedJobs,
  setSidebarOpen,
} from "../../../store/slices/bookmarksSlice"
import useBookmark from "../../../lib/use-bookmark"
import Badge from "../../Badge"

function BookmarkButton({
  className,
  variant = "button",
  buttonVariant = "tertiary",
  buttonAsStyle = "button",
  buttonShowText = true,
  iconPosition = "right",
  singleJobId = false,
  iconSize = 24,
  hideIfNoBookmarks = false,
  buttonTextBold = true,
  overrideText = null,
  skeleton = false,
  showBadge = false,
  rootProps = {},
}) {
  const { t } = useTranslation("jobsearch")

  const [hasBookmarks, setHasBookmarks] = useState(false)
  const dispatch = useDispatch()

  const [showAnimation, setShowAnimation] = useState(false)

  const bookmarks = useBookmarks()
  useEffect(() => {
    setHasBookmarks(bookmarks?.bookmarks?.length > 0 || false)
  }, [bookmarks])

  const [isBookmarked, toggleBookmark] = useBookmark(singleJobId ?? null)

  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const buttonText = overrideText
    ? singleJobId
      ? isBookmarked
        ? overrideText.active
        : overrideText.inactive
      : overrideText.default
    : t("bookmarks.label")

  return hideIfNoBookmarks && !hasBookmarks ? null : (
    <Button
      role="button"
      aria-label={
        (buttonShowText ? buttonText : "") +
        " " +
        (bookmarks?.bookmarks?.length ? bookmarks.bookmarks.length : "")
      }
      variant={buttonVariant}
      asStyle={buttonAsStyle}
      suppressHydrationWarning={true}
      textBold={buttonTextBold}
      className={cx(
        styles.BookmarkButton,
        {
          [styles.hasBookmarks]: singleJobId ? isBookmarked : hasBookmarks,
          [styles[variant]]: variant,
          "p-0 focus:outline-0": buttonAsStyle === "link",
        },
        className,
      )}
      iconRight={
        iconPosition === "right" ? (
          <Star
            className={cx({
              [styles.starAnimation]: showAnimation,
            })}
            size={iconSize}
          />
        ) : null
      }
      iconLeft={
        iconPosition === "left" ? (
          <Star
            className={cx({
              [styles.starAnimation]: showAnimation,
            })}
            size={iconSize}
          />
        ) : null
      }
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()

        if (skeleton) return

        if (singleJobId) {
          toggleBookmark()
        } else {
          dispatch(setSidebarOpen(!bookmarks?.sidebar_open))
        }

        dispatch(getBookmarkedJobs())

        setShowAnimation(true)
        setTimeout(() => {
          setShowAnimation(false)
        }, 500)
      }}
      style={{ position: "relative" }}
      {...rootProps}
    >
      {isClient &&
      showBadge &&
      bookmarks?.bookmarks?.length &&
      bookmarks.bookmarks.length > 0 ? (
        <Badge
          color={"red"}
          circle={true}
          className={styles.badge}
          aria-label={bookmarks?.bookmarks?.length || ""}
        >
          {bookmarks?.bookmarks?.length}
        </Badge>
      ) : null}

      {buttonShowText ? <span>{buttonText}</span> : null}
    </Button>
  )
}

export default BookmarkButton

import styles from "./Skeleton.module.scss"
import PropTypes from "prop-types"
import cx from "classnames"

const Skeleton = ({ width = null, height = null, rounded = false }) => {
  return (
    <div
      style={{ width, height }}
      className={cx(styles.Skeleton, {
        [styles.rounded]: rounded,
      })}
    ></div>
  )
}

PropTypes.Skeleton = {
  width: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  height: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  rounded: PropTypes.bool,
}

export default Skeleton
